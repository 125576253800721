import React from 'react';
import "./normalcart.scss";
import { useNavigate } from 'react-router-dom';

// Define a type for the content items
interface ContentItem {
    image: any;
    category_name: string;
}

interface AppProps {
    data: ContentItem[]; // Use the defined type for data
}

const NormalCart: React.FC<AppProps> = ({ data }) => {
    const navigate = useNavigate()
    // console.log(data)

    const handleClick = async (id: any) => {
        navigate(`/products/${id}`)
    }
    return (
        <div className="NormalCart">
            {data.map((content: any, index) => (
                <div
                    onClick={() => handleClick(content?._id)} className="NormalCart-container" key={index}> {/* Add a unique key */}
                    <img src={content?.image?.banner || ""} alt={content.category_name} /> {/* Use alt text */}
                    <h2>{content.category_name}</h2>
                </div>
            ))}
        </div>
    );
}

export default NormalCart;
