import React from 'react';
import "./footer.scss";

// Define a type for the content items


const Footer = () => {

    return (
        <div className='Footer-Component'>
            <div className="Footer-Component-Content">
                <div className='Footer-Section Address'>
                    <h1 className='text-primary_text'>Funiro</h1>
                    <h2 className='text-secondary_text'>400 University Drive Suite 200 Coral Gables,
                        FL 33134 USA</h2>
                </div>
                <div className='Footer-Section Links'>
                    <h1 className='text-secondary_text'>Links</h1>
                    <h2 className='text-primary_text'>Home</h2>
                    <h2 className='text-primary_text'>Shop</h2>
                    <h2 className='text-primary_text'>About</h2>
                    <h2 className='text-primary_text'>Contact</h2>
                </div>
                <div className='Footer-Section Help'>
                    <h1 className='text-secondary_text'>Help</h1>
                    <h2 className='text-primary_text'>Payment Option</h2>
                    <h2 className='text-primary_text'>Return</h2>
                    <h2 className='text-primary_text'>Privacy Policy</h2>

                </div>
                <div className='Footer-Section Newsletter'>
                    <h1 className='text-secondary_text'>New Letter</h1>
                    <h2 className='text-primary_text'>Subscribe</h2>
                </div>

               
            </div>
            <h3 className='pt-[40px]'>2023 furino. All rights reverved</h3>
        </div>

    );
}

export default Footer;
