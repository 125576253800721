import * as React from 'react';
import { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import './group_radio.scss'



export default function RadioButtonsGroup({ content, productList }: any) {
    const [checkedIds, setCheckedIds] = useState<string[]>([]);

    const handleSelectRadio = (id: string) => {
        // Update the state
        const updatedCheckedIds = [id] // Add if not already checked

        setCheckedIds(updatedCheckedIds); // Update the state

        // Get the current URL
        const currentUrl = new URL(window.location.href);

        // Extract the current path
        let currentPath = currentUrl.pathname;

        // Remove any existing "category=" segment
        currentPath = currentPath.replace(/%20price=[^\/]+/, "");

        // Add the updated "category=" segment if there are selected IDs
        if (updatedCheckedIds.length > 0) {
            const categoryPath = `%20price=${updatedCheckedIds}`; // URL-encoded '%' is '%25'
            currentPath += categoryPath;
        }

        // Update the browser URL without reloading the page
        window.history.replaceState(null, "", currentPath);
    };

    useEffect(() => {
        productList({ price: checkedIds });
    }, [checkedIds])

    // useEffect(() => {
    //     // Parse the current URL
    //     const currentUrl = new URL(window.location.href);
      
    //     // Extract the path after `/products/`
    //     const pathParts = currentUrl.pathname.split("/products/")[1]?.split("/price=");
      
    //     if (pathParts?.[1]) {
    //       // Get the `category` part and decode it
    //       const subcategoryIds = pathParts[1].split("%25").map((id) => id.trim());
    //       setCheckedIds(subcategoryIds); // Update the state
    //     }
    //   }, []);
      useEffect(() => {
        // Parse the current URL
        const currentUrl = new URL(window.location.href);
    
        // Extract the path after `/products/`
        const pathAfterProducts = currentUrl.pathname.split("/products/")[1] || "";
    
        // Split by `%20category=` to isolate the category part
        const categoryPart = pathAfterProducts.split("%20price=")[1];
    
        if (categoryPart) {
          // Decode the entire category part
          const decodedCategoryPart = decodeURIComponent(categoryPart);
    
          // Split by ',' or '%' to handle both separators
          const subcategoryIds = decodedCategoryPart.split(/[,|%]/).map((id) => id.trim());
    
          // Update the state with the extracted IDs as an array
          setCheckedIds(subcategoryIds.filter((id) => id)); // Filter out any empty strings
        }
      }, []);

    console.log()
    return (
        <FormControl fullWidth className='filter-div'>
            <FormLabel id="demo-radio-buttons-group-label" className='label'>{content?.title}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                {content?.value?.map((item: any) => (
                    <FormControlLabel value={item?._id} control={<Radio className='radio-button' checked={checkedIds.includes(item._id)} onChange={() => handleSelectRadio(item?._id)} />} label={item?.value} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}