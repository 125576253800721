import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home/index";
import ProductPage from './pages/Products';
import CategoryPage from './pages/Category';
import { fetchHomePageData, useAppDispatch } from "./pages/Home/controller";
import { useEffect } from 'react';
function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchHomePageData());
  }, [])
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/:category_id/*" element={<CategoryPage />} />
      </Routes>
    </div>
  );
}

export default App;
