import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../state_store/index' // Importing AppDispatch type for Thunks
import { getRequest } from '../../services'
import { useDispatch } from 'react-redux';


interface CategoryState {
    data: any[]
}

const initialState: CategoryState = {
    data: []
}

const CategorySlice = createSlice({
    name: 'Category',
    initialState,
    reducers: {
        setCategory(state, action: PayloadAction<any[]>) {
            state.data = action.payload  // Update state with action payload
        }
    }
})

// Export actions
export const { setCategory } = CategorySlice.actions
// Export reducer
export default CategorySlice.reducer



const makeFilterString = (filterData: any) => {
    if (!Array.isArray(filterData) || filterData.length === 0) {
        return '';
    }
    return filterData.map((id) => encodeURIComponent(id)).join('%2C');
};
// Thunk Function (Async Action Creator)

export const fetchProductsCategories = ({ category, price, page, rpp }: { category: any, price: any, page: any, rpp: any }) => async (dispatch: AppDispatch) => {
    try {
        // console.log(category, price)
        // Example: fetching data from an API
        const filterCategory = makeFilterString(category)
        const filterPrice = price

        // console.log(filterCategory)
        // Add filterCategory if it exists
        let URL = `products?$page=${page}&$rpp=${rpp}`;

        if (filterCategory) {
            URL += `&$filter=category_id%20in%20${filterCategory}`;
        }

        // Add filterPrice if it exists
        if (filterPrice) {
            URL += `&$price=${filterPrice}`;
        }

        const response: any = await getRequest(URL)
        if (response.status) {
            dispatch(setCategory(response.data))
        } else {
            dispatch(setCategory([]))
        }
    } catch (error) {
        console.error('Error fetching Category data', error)
        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}
export const useAppDispatch: () => AppDispatch = useDispatch;