import React from 'react';
import "./customersupport.scss";

// Define a type for the content items

interface AppProps {
    image: string; // Use the defined type for data
}

const CustomerSupport: React.FC<AppProps> = ({ image }) => {

    return (
        <div className="Customer_support">
            <img src={image} alt="support" />
        </div>
    );
}

export default CustomerSupport;
