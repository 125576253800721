import * as React from 'react';
import { useState } from 'react';
import { Button, FormControl, FormLabel, TextField, Box } from '@mui/material';
import "./select_box_with_search.scss"



export default function SizeSelector({ categories }: any) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);

  // Filter sizes based on the search term
  const filteredSizes = categories?.options.filter((size: any) =>
    size.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Determine the sizes to show based on the `showAll` state
  const sizesToShow = showAll ? filteredSizes : filteredSizes.slice(0, 9);

  return (
    <FormControl fullWidth className='filter-div'>
      <FormLabel className='label'>{categories?.label}</FormLabel>
      <TextField
        variant="outlined"
        placeholder="Search for Size"
        size="small"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
        {sizesToShow.map((size: any, index: any) => (
          <Button
            key={index}
            variant="outlined"
            sx={{
              borderRadius: '8px',
              minWidth: '50px',
              padding: '6px 12px',
              textTransform: 'none',
            }}
            className='CheckBox_Size'
          >
            {size}
          </Button>
        ))}
      </Box>
      {filteredSizes.length > 9 && (
        <Button
        className='button'
          onClick={() => setShowAll(!showAll)}
          sx={{ mt: 1, color: 'primary.main', textTransform: 'none' }}
        >
          {showAll ? "- Show Less" : `+ ${filteredSizes.length - 9} more`}
        </Button>
      )}
    </FormControl>
  );
}
