import React from 'react';
import "./productcart.scss";
import { useSelector } from 'react-redux'
import { calculatePercentageDifference } from '../../../utlis';
import CustomButton from '../../Button';

// Define a type for the content items
interface ContentItem {
    images: any;
    name: string;
    category: string;
    max_price: string;
    base_price: string;
}

interface AppProps {
    data: ContentItem[]; // Use the defined type for data
}

const ProductCart: React.FC<AppProps> = ({ data }) => {
    const homePageData = useSelector((state: any) => state.homePage.data)
    return (
        <div className="ProductCart">
            {data.map((content, index) => (
                <div className="ProductCart-container" key={index}>
                    <img src={content.images[0]?.banner || ""} alt={content.name} />
                    <div className='ProductCart-text'>
                        <h1 className='text-primary_text'>{content.name}</h1>
                        <h3 className='text-secondary_text'>{content.category}</h3>
                        <div>
                            <h2 className='text-primary_text'>{homePageData[0]?.currency} {content.base_price}</h2>
                            {content.max_price && <h2 className='line-through text-secondary_text'>{homePageData[0]?.currency} {content.max_price}</h2>}
                        </div>

                    </div>
                    {content.max_price && <div className='ProductCart-Discount'>
                        -{calculatePercentageDifference(Number(content.base_price), Number(content.max_price))}%
                    </div>}
                    <div className="ProductCart-overlay">
                        <CustomButton lable={"Add to Cart"} type="outlined" />
                        <div className='flex p-[10px] justify-center gap-[20px] text-white'>
                            <h3>Share</h3>
                            <h3>Compare</h3>
                            <h3>Likes</h3>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProductCart;
