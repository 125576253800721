import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import "./button.scss"


interface AppProps {
    lable: string;
    type: 'text' | 'outlined' | 'contained';
}

const CustomButton: React.FC<AppProps> = ({ lable, type }) => {
    return (
        <div className={type === "contained" ? "Button-container-contained": "Button-container"}>
               <Button variant={type} className='button'>{lable}</Button>
            
        </div>
    );
}

export default CustomButton;