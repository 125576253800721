export const calculatePercentageDifference = (basePrice: number, maxPrice: number) => {
    // Check if the basePrice is valid
    if (basePrice <= 0) {
        throw new Error("Base price must be greater than 0.");
    }

    // Calculate the percentage difference
    const difference = maxPrice - basePrice;
    const percentage = (difference / basePrice) * 100;

    return percentage;
}